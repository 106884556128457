var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import axios from 'axios';
import GeneralMessage from '../ui/general-message';
import ClaimInfo from '../application/claim-info';
import Icon from '../ui/icon';
import Loader from '../ui/loader';
var styles = require('./claim-detail.scss');
var ClaimDetail = /** @class */ (function (_super) {
    __extends(ClaimDetail, _super);
    function ClaimDetail(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            vin: '',
            claim: null,
            isLoading: true,
            isError: false
        };
        return _this;
    }
    ClaimDetail.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var queryParams, claims, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        queryParams = __rest(queryString.parse(this.props.location.search), []);
                        if (!queryParams.id || !queryParams.vin || !queryParams.lastName || !queryParams.zipCode) {
                            this.setState({ isError: true, isLoading: false });
                            return [2 /*return*/];
                        }
                        this.setState({ vin: queryParams.vin });
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.fetchClaim(queryParams)];
                    case 2:
                        claims = _b.sent();
                        this.setState({ isLoading: false, claim: claims[0] });
                        return [3 /*break*/, 4];
                    case 3:
                        _a = _b.sent();
                        this.setState({ isError: true, isLoading: false });
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ClaimDetail.prototype.render = function () {
        var contents;
        if (this.state.isLoading) {
            contents = React.createElement(Loader, null);
        }
        else if (this.state.isError) {
            contents = (React.createElement(GeneralMessage, { type: "error", title: "System Error", description: 'An unexpected error has occurred. Please try again later.' }));
        }
        else if (!this.state.isLoading && !this.state.claim) {
            contents = (React.createElement(GeneralMessage, { type: "warning", title: "No Open Claim Found", description: "No open claim has been found. Please contact the JNR hotline for assistance." }));
        }
        else {
            var claim = this.state.claim;
            contents = (React.createElement(React.Fragment, null,
                React.createElement("div", { className: styles.backBar },
                    React.createElement(Link, { to: {
                            pathname: '/claims',
                            search: "vin=" + this.state.claim.vin + "&lastName=" + claim.lastName + "&zipCode=" + claim.zipCode
                        } },
                        React.createElement(Icon, { icon: 'back_icon', class: 'iconBack', width: '25', height: '25' }))),
                React.createElement("div", { className: styles.container },
                    React.createElement(ClaimInfo, { claim: claim }))));
        }
        return (React.createElement("article", { className: styles.summaryContainer },
            React.createElement("section", null, contents)));
    };
    ClaimDetail.prototype.fetchClaim = function (queryParams) {
        return __awaiter(this, void 0, void 0, function () {
            var id, vin, lastName, zipCode, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        id = queryParams.id, vin = queryParams.vin, lastName = queryParams.lastName, zipCode = queryParams.zipCode;
                        return [4 /*yield*/, axios.get('/api/claim-summary', { params: { id: id, vin: vin, lastName: lastName, zipCode: zipCode } })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data.claims];
                }
            });
        });
    };
    return ClaimDetail;
}(React.Component));
export default ClaimDetail;
