var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import * as React from 'react';
export var Provider = (_a = React.createContext({
    vin: '',
    submittable: [],
    legacy: [],
    update: function () { },
    updateSubmittable: function () { },
    updateLegacy: function () { }
}), _a.Provider), Consumer = _a.Consumer;
export var withVin = function (Child) {
    return function (props) {
        return (React.createElement(Consumer, null, function (context) {
            return React.createElement(Child, __assign({ vin: context.vin, submittable: context.submittable, legacy: context.legacy }, props));
        }));
    };
};
