import * as React from 'react';
var styles = require('./side-bar.scss');
var SideBar = function (props) {
    return (React.createElement("aside", null,
        React.createElement("span", { className: styles.currentVin },
            "VIN: ",
            props.vin),
        React.createElement("p", null, "Mazda has a reimbursement program in place for repairs performed on your vehicle that were related to a recall or service campaign where you paid out of pocket for the expense prior to the launch of the campaign. To file a claim, choose the campaign and fill out the form attaching the necessary documents related to the repair."),
        React.createElement("p", null,
            React.createElement("span", { className: styles.title }, "Reimbursement Claim Form"),
            React.createElement("br", null),
            "In order to process your claim completely, please have the following information.",
            React.createElement("ul", null,
                React.createElement("li", null, "Your current name and address"),
                React.createElement("li", null, "A current, valid email address"),
                React.createElement("li", null,
                    "Paid repair order or invoice receipt showing:",
                    React.createElement("ul", null,
                        React.createElement("li", null, "Vehicle model and year, and vehicle identification number (VIN)"),
                        React.createElement("li", null, "Your name and address at the time of repair"),
                        React.createElement("li", null, "Description of the recall defect or noncompliance concern reported"),
                        React.createElement("li", null, "Total amount paid for repair of the concern"),
                        React.createElement("li", null, "Proof of payment for repairs completed (cash or credit card receipt, or cancelled check copy)")))),
            "Note: Additional information may be required if the repair order or invoice receipt are unclear that the repair addresses the recall defect or noncompliance. For a replacement vehicle part, the receipt must identify the item and the total amount paid.")));
};
export default SideBar;
