var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
var styles = require('./incompatible.scss');
var warningIcon = 'https://jnr-email-assets.s3-us-west-2.amazonaws.com/icons/icon-alert.png';
var firefoxIcon = 'https://jnr-email-assets.s3-us-west-2.amazonaws.com/icons/icon-firefox.png';
var safariIcon = 'https://jnr-email-assets.s3-us-west-2.amazonaws.com/icons/icon-safari.png';
var chromeIcon = 'https://jnr-email-assets.s3-us-west-2.amazonaws.com/icons/icon-chrome.png';
var edgeIcon = 'https://jnr-email-assets.s3-us-west-2.amazonaws.com/icons/icon-edge.png';
var Incompatible = /** @class */ (function (_super) {
    __extends(Incompatible, _super);
    function Incompatible() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Incompatible.prototype.render = function () {
        return (React.createElement("div", { className: styles.page },
            React.createElement("img", { src: warningIcon, className: styles.logo }),
            React.createElement("div", { className: styles.text },
                React.createElement("h1", null, "Your browser is not supported"),
                React.createElement("p", null, "For the best experience, please use one of the browsers below."),
                React.createElement("table", null,
                    React.createElement("tr", null,
                        React.createElement("td", null,
                            React.createElement("a", { href: "https://www.microsoft.com/en-us/windows/microsoft-edge", target: "_blank" },
                                React.createElement("img", { src: edgeIcon, className: styles.icon }))),
                        React.createElement("td", null,
                            React.createElement("a", { href: "https://www.google.com/chrome/", target: "_blank" },
                                React.createElement("img", { src: chromeIcon, className: styles.icon }))),
                        React.createElement("td", null,
                            React.createElement("a", { href: "https://www.mozilla.org/en-US/firefox/new/", target: "_blank" },
                                React.createElement("img", { src: firefoxIcon, className: styles.icon }))),
                        React.createElement("td", null,
                            React.createElement("a", { href: "https://support.apple.com/downloads/safari", target: "_blank" },
                                React.createElement("img", { src: safariIcon, className: styles.icon }))))))));
    };
    return Incompatible;
}(React.Component));
export default Incompatible;
