import React from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
var styles = require('./title-bar.scss');
var logo = "https://jnr-image-assets.s3.amazonaws.com/mazda/logo/mazda_small.png";
var TitleBar = function (props) {
    var title = props.history.location.pathname.startsWith('/claims') ? 'Check Recall Status' : 'Submit a Claim';
    return (React.createElement("nav", { className: styles.titleBar },
        React.createElement(Link, { to: "/" },
            React.createElement("img", { src: logo, alt: "logo" })),
        React.createElement("p", null, title)));
};
export default withRouter(TitleBar);
