import * as React from 'react';
import { ProgressStatus } from './claim-wizard';
var styles = require('./progress-bar.scss');
var ProgressBar = function (props) {
    var progressBarStyle = {
        transition: 'width 0.25s',
        width: props.progress,
        backgroundColor: props.progress === ProgressStatus.COMPLETE ? '#04844b' : '#367397'
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "progressBarContainer", className: styles.progressBarContainer },
            React.createElement("div", { className: styles.progressBar, style: progressBarStyle }),
            React.createElement("div", { className: styles.progressStages },
                React.createElement("div", null,
                    React.createElement("span", null, "Recall Look Up")),
                React.createElement("div", null,
                    React.createElement("span", { className: props.progress == ProgressStatus.CAMPAIGN ? styles.active : '' }, "Recall Information")),
                React.createElement("div", null,
                    React.createElement("span", { className: props.progress == ProgressStatus.CONTACT ? styles.active : '' }, "Claimant & Reimbursement Information")),
                React.createElement("div", null,
                    React.createElement("span", { className: props.progress == ProgressStatus.COMPLETE ? styles.complete : '' }, "Claim Submitted"))))));
};
export default ProgressBar;
