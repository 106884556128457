import _ from 'lodash';
export var StatusDefinitions = {
    Closed: 10,
    Open: 11,
    'No remedy': 12,
    'Warranty Active': 13,
    'Warranty Expired': 14
};
export var statusConversion = function (status) {
    var statusPairs = _.toPairs(StatusDefinitions);
    for (var index in statusPairs) {
        if (statusPairs[index][0] === status) {
            return statusPairs[index][1];
        }
        else if (statusPairs[index][1] === status) {
            return statusPairs[index][0];
        }
    }
    throw Error("Invalid status code or name supplied. Allowed values: " + _.flatten(statusPairs));
};
export var isOpenStatus = function (status) {
    return typeof status == 'number' ? status === 11 : status === 'Open';
};
export var isNoRemedyStatus = function (status) {
    return typeof status == 'number' ? status === 12 : status === 'No remedy';
};
