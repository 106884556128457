import React from 'react';
var styles = require('./icon.scss');
var Icon = function (props) {
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", className: styles[props.class], fill: props.fill, width: props.width, height: props.height },
        React.createElement(BackIcon, null),
        React.createElement(RemoveIcon, null),
        React.createElement(FileIcon, null),
        React.createElement(ErrorIcon, null),
        React.createElement(WarningIcon, null),
        React.createElement(SuccessIcon, null),
        React.createElement("use", { href: "#" + props.icon })));
};
var BackIcon = function () {
    return (React.createElement("symbol", { id: "back_icon", viewBox: "0 0 24 24" },
        React.createElement("path", { d: "M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" })));
};
var RemoveIcon = function () {
    return (React.createElement("symbol", { id: "remove_icon", viewBox: "0 0 24 24" },
        React.createElement("path", { fill: "none", d: "M0 0h24v24H0V0z" }),
        React.createElement("path", { d: "M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V9c0-1.1-.9-2-2-2H8c-1.1 0-2 .9-2 2v10zM18 4h-2.5l-.71-.71c-.18-.18-.44-.29-.7-.29H9.91c-.26 0-.52.11-.7.29L8.5 4H6c-.55 0-1 .45-1 1s.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1z" })));
};
var FileIcon = function () {
    return (React.createElement("symbol", { id: "file_icon", viewBox: "0 0 15 18" },
        React.createElement("g", { id: "Style-Guide", stroke: "none", strokeWidth: "1", fill: "none", fillRule: "evenodd" },
            React.createElement("g", { transform: "translate(-128.000000, -2109.000000)", id: "Icon-/-File" },
                React.createElement("g", { transform: "translate(123.000000, 2106.000000)" },
                    React.createElement("g", { id: "Material/Icons-black/document" },
                        React.createElement("polygon", { id: "Path", points: "0 0 24 0 24 24 0 24" }),
                        React.createElement("path", { d: "M14,3 L20,9 L20,19 C20,20.1 19.1,21 18,21 L6.99,21 C5.89,21 5,20.1 5,19 L5.01,5 C5.01,3.9 5.9,3 7,3 L14,3 Z M13,10 L18.5,10 L13,4.5 L13,10 Z", id: "Mask", fill: "#000000", "fill-rule": "nonzero" })))))));
};
var ErrorIcon = function () {
    return (React.createElement("symbol", { id: "error_icon", viewBox: "0 0 31 31" },
        React.createElement("g", { transform: "translate(-66.000000, -1391.000000)", fill: "#FFFFFF", id: "Group-2" },
            React.createElement("g", { transform: "translate(48.000000, 1376.000000)" },
                React.createElement("g", { id: "ic_add_circle_outline", transform: "translate(33.627417, 30.870058) rotate(-45.000000) translate(-33.627417, -30.870058) translate(14.627417, 11.870058)" },
                    React.createElement("g", { id: "Icon-24px", transform: "translate(0.671573, 0.148232)" },
                        React.createElement("path", { d: "M20.0416667,10.7916667 L16.9583333,10.7916667 L16.9583333,16.9583333 L10.7916667,16.9583333 L10.7916667,20.0416667 L16.9583333,20.0416667 L16.9583333,26.2083333 L20.0416667,26.2083333 L20.0416667,20.0416667 L26.2083333,20.0416667 L26.2083333,16.9583333 L20.0416667,16.9583333 L20.0416667,10.7916667 L20.0416667,10.7916667 Z M18.5,3.08333333 C9.99,3.08333333 3.08333333,9.99 3.08333333,18.5 C3.08333333,27.01 9.99,33.9166667 18.5,33.9166667 C27.01,33.9166667 33.9166667,27.01 33.9166667,18.5 C33.9166667,9.99 27.01,3.08333333 18.5,3.08333333 L18.5,3.08333333 Z M18.5,30.8333333 C11.70125,30.8333333 6.16666667,25.29875 6.16666667,18.5 C6.16666667,11.70125 11.70125,6.16666667 18.5,6.16666667 C25.29875,6.16666667 30.8333333,11.70125 30.8333333,18.5 C30.8333333,25.29875 25.29875,30.8333333 18.5,30.8333333 L18.5,30.8333333 Z", id: "Shape" })))))));
};
var WarningIcon = function () {
    return (React.createElement("symbol", { id: "warning_icon", viewBox: "0 0 35 30" },
        React.createElement("g", { id: "Style-Guide", stroke: "none", strokeWidth: "1", fill: "none", fillRule: "evenodd" },
            React.createElement("g", { transform: "translate(-68.000000, -1561.000000)", fill: "#000000", fillRule: "nonzero", id: "Toast_Warning" },
                React.createElement("g", { transform: "translate(48.000000, 1544.000000)" },
                    React.createElement("path", { d: "M54.5968245,43.25 L39.1696019,18.0227273 C38.3611885,16.6590909 36.6096261,16.6590909 35.8012127,18.0227273 L20.37399,43.25 C19.4308411,44.8181818 20.37399,47 22.0581847,47 L52.9126299,47 C54.5968245,47 55.6073413,44.8181818 54.5968245,43.25 Z M37,41 C35.8666667,41 35,40.1333333 35,39 C35,37.8666667 35.8666667,37 37,37 C38.1333333,37 39,37.8666667 39,39 C39,40.1333333 38.1333333,41 37,41 Z M39,35.3333333 C39,35.7333333 38.7333333,36 38.3333333,36 L35.6666667,36 C35.2666667,36 35,35.7333333 35,35.3333333 L35,26.6666667 C35,26.2666667 35.2666667,26 35.6666667,26 L38.3333333,26 C38.7333333,26 39,26.2666667 39,26.6666667 L39,35.3333333 Z", id: "Shape" }))))));
};
var SuccessIcon = function () {
    return (React.createElement("symbol", { id: "success_icon", viewBox: "0 0 32 32" },
        React.createElement("g", { id: "Style-Guide", stroke: "none", strokeWidth: "1", fill: "none", fillRule: "evenodd" },
            React.createElement("g", { transform: "translate(-66.000000, -1212.000000)", fill: "#FFFFFF", fillRule: "nonzero", id: "Group-2" },
                React.createElement("g", { transform: "translate(48.000000, 1197.000000)" },
                    React.createElement("g", { id: "baseline-check_circle_outline-24px-(1)", transform: "translate(15.000000, 13.000000)" },
                        React.createElement("path", { d: "M26.2186774,11.0450968 L15.9935484,21.2702258 L10.4232581,15.7154516 L8.23548387,17.9032258 L15.9935484,25.6612903 L28.4064516,13.2483871 L26.2186774,11.0450968 Z M19.0967742,2.38709677 C10.531871,2.38709677 3.58064516,9.33832258 3.58064516,17.9032258 C3.58064516,26.468129 10.531871,33.4193548 19.0967742,33.4193548 C27.6616774,33.4193548 34.6129032,26.468129 34.6129032,17.9032258 C34.6129032,9.33832258 27.6616774,2.38709677 19.0967742,2.38709677 Z M19.0967742,30.316129 C12.2386452,30.316129 6.68387097,24.7613548 6.68387097,17.9032258 C6.68387097,11.0450968 12.2386452,5.49032258 19.0967742,5.49032258 C25.9549032,5.49032258 31.5096774,11.0450968 31.5096774,17.9032258 C31.5096774,24.7613548 25.9549032,30.316129 19.0967742,30.316129 Z", id: "Shape" })))))));
};
export default Icon;
