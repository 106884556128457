import React from 'react';
import NumberFormat from 'react-number-format';
import Status from '../find-claim/status';
import Icon from '../ui/icon';
import Interweave from 'interweave';
import moment from 'moment';
var globalStyles = require('./application.scss');
var styles = require('./claim-info.scss');
var ClaimInfo = function (_a) {
    var claim = _a.claim;
    return (React.createElement("div", { className: styles.claimInfo, "data-test": "claim_summary" },
        React.createElement("h2", null, "Customer Reimbursement Claim Details"),
        React.createElement("div", null,
            React.createElement("p", { className: globalStyles.instructions }, "Please see a summary of your requested information. All requests are processed in 30 calendar days from receipt of this form. Please note that all Open recalls must be completed prior to claim payment except where the vehicle was sold. Missing or incomplete documentation may also delay payment for your claim. We may ask for secondary documents to support your claim via email, phone or by US Mail.")),
        React.createElement("div", { className: styles.statusBar }),
        claim.status && React.createElement(Status, { status: claim.status }),
        React.createElement("div", { style: { display: 'none' }, "data-test": 'claim_summary_id' }, encodeURIComponent(claim.id)),
        claim.created && (React.createElement("section", null,
            React.createElement("div", null,
                React.createElement("div", { className: globalStyles.title }, "Submitted"),
                React.createElement("div", null, moment(claim.created).format("MM-DD-YYYY"))))),
        React.createElement("section", null,
            React.createElement("h3", null, "Recall"),
            React.createElement("div", null,
                React.createElement("div", { className: globalStyles.title }, "Campaign"),
                React.createElement("div", null,
                    React.createElement("div", null, claim.campaign.projectName),
                    React.createElement("div", { className: globalStyles.campaignInstructions },
                        React.createElement(Interweave, { content: claim.campaign.instructions }))))),
        React.createElement("section", null,
            React.createElement("h3", null, "Reimbursement"),
            React.createElement("div", null,
                React.createElement("div", { className: globalStyles.title }, "Requested Reimbursement"),
                React.createElement("div", { className: styles.reimbursementAmount },
                    React.createElement(NumberFormat, { id: "amount", displayType: 'text', thousandSeparator: true, prefix: '$', decimalScale: 2, value: claim.amount }))),
            claim.files.length > 0 && (React.createElement("div", { className: styles.fileList },
                React.createElement("div", { className: globalStyles.title }, "Attachments"),
                claim.files.map(function (file, index) {
                    return (React.createElement("div", { key: index, className: styles.fileSet },
                        React.createElement("div", null,
                            React.createElement(Icon, { icon: 'file_icon', class: 'iconFile', fill: '', width: '18', height: '18', alt: "File" })),
                        React.createElement("div", { className: styles.fileName }, file.filename || file.name)));
                })))),
        React.createElement("section", null,
            React.createElement("h3", null, "Claimant Information"),
            React.createElement("div", null,
                React.createElement("div", { className: globalStyles.title }, "Mailing Address"),
                React.createElement("div", null,
                    React.createElement("div", null,
                        claim.firstName,
                        " ",
                        claim.lastName),
                    React.createElement("div", null, claim.addressOne),
                    claim.addressTwo && React.createElement("div", null, claim.addressTwo),
                    React.createElement("div", null,
                        claim.city,
                        ", ",
                        claim.state,
                        " ",
                        claim.zipCode))),
            React.createElement("div", null,
                React.createElement("div", { className: globalStyles.title }, "Contact Info"),
                React.createElement("div", null,
                    React.createElement(NumberFormat, { id: "phoneNumber", displayType: 'text', value: claim.phoneNumber, format: "(###) ###-####" }),
                    claim.emailAddress && React.createElement("div", null, claim.emailAddress))))));
};
export default ClaimInfo;
