import React from 'react';
import { withRouter } from 'react-router';
var styles = require('./footer-bar.scss');
var accessiblelogo = "https://jnr-image-assets.s3.amazonaws.com/mazda/logo/mazda_small.png";
var FooterBar = function (props) {
    var title = props.history.location.pathname.startsWith('/claims') ? 'Check Recall Status' : 'Submit a Claim';
    return (React.createElement("nav", { className: styles.footerBar },
        React.createElement("div", { className: styles.footerlinks },
            " ",
            React.createElement("a", { href: "https://www.mazdausa.com/site/terms-of-use", target: "_blank" }, "Terms and Conditions"),
            " "),
        React.createElement("div", { className: styles.footerlinksafter }),
        React.createElement("div", { className: styles.footerlinks },
            " ",
            React.createElement("a", { href: "https://www.mazdausa.com/site/privacy", target: "_blank" }, "Privacy Policy"),
            " "),
        React.createElement("div", { className: styles.footerlinksafter }),
        React.createElement("div", { className: styles.footerlinks },
            " ",
            React.createElement("a", { href: "https://privacy.mazdausa.com/us/request_opt_out_form", target: "_blank" }, "Do Not Sell or Share My Personal Information")),
        React.createElement("div", { className: styles.footerlinksafter }),
        React.createElement("div", { className: styles.footerlinksAccess },
            React.createElement("a", { "data-acsb-custom-trigger": "true" },
                React.createElement("img", { src: "https://img.icons8.com/ios-filled/50/D5D5D5/accessibility2.png" }),
                "Accessibility")),
        React.createElement("div", { className: styles.staticText },
            React.createElement("p", null, "This site is owned and operated by JNR, Inc. Mazda and its logo are the registered trademarks of Mazda Motor of America, Inc. and its affiliates and licensed to JNR, Inc. pursuant to a services agreement.")),
        React.createElement("div", { className: styles.footerlinks },
            " ",
            React.createElement("p", null,
                "JNR Incorporated \u00A9 ",
                (new Date().getFullYear())))));
};
export default withRouter(FooterBar);
