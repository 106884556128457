var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Field, connect } from 'formik';
import FieldError from './field-error';
import NumberFormat from 'react-number-format';
var styles = require('./form-field.scss');
var FormField = function (_a) {
    var name = _a.name, label = _a.label, formik = _a.formik, inputType = _a.inputType, children = _a.children, props = __rest(_a, ["name", "label", "formik", "inputType", "children"]);
    var isInvalid = formik.errors[name] && formik.touched[name];
    var input;
    if (inputType === 'numberField') {
        input = (React.createElement(NumberFormat, __assign({ id: name, className: isInvalid && styles.invalid, onValueChange: function (_a) {
                var value = _a.value;
                return formik.setFieldValue(name, value);
            }, onBlur: function () { return formik.setFieldTouched(name, true); } }, props)));
    }
    else if (children) {
        input = (React.createElement(Field, __assign({ id: name, name: name, className: isInvalid && styles.invalid }, props), children));
    }
    else {
        input = React.createElement(Field, __assign({ id: name, name: name, className: isInvalid && styles.invalid }, props));
    }
    return (React.createElement("div", { className: styles.formField },
        label && React.createElement("label", { htmlFor: name }, label),
        input,
        React.createElement(FieldError, { name: name })));
};
export var RadialSelection = function (_a) {
    var name = _a.name, label = _a.label, selection = _a.selection, options = _a.options, selected = _a.selected, props = __rest(_a, ["name", "label", "selection", "options", "selected"]);
    return (React.createElement("div", __assign({ className: styles.formField }, props),
        label && React.createElement("label", { htmlFor: name }, label),
        React.createElement("div", { id: name, className: styles.group }, options.map(function (option) {
            return (React.createElement("div", { onClick: function () { return selection(option); }, className: option === selected ? styles.selected : styles.item }, option));
        }))));
};
export default connect(FormField);
