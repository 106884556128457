import * as React from 'react';
import { Link } from 'react-router-dom';
import GeneralMessage from '../ui/general-message';
import { GENERAL_ERROR_PAGE_MESSAGE } from '../constants';
var styles = require('./error.scss');
var Error = function () {
    return (React.createElement("div", { className: styles.errorContainer },
        React.createElement(GeneralMessage, { type: "error", title: "Error", description: GENERAL_ERROR_PAGE_MESSAGE }),
        React.createElement(Link, { to: "/" }, " Back to VIN lookup")));
};
export default Error;
