var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import { Prompt, Redirect } from 'react-router';
import _ from 'lodash';
import Modal from './modal';
var PreventTransitionPrompt = /** @class */ (function (_super) {
    __extends(PreventTransitionPrompt, _super);
    function PreventTransitionPrompt(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            showModal: false,
            redirect: false
        };
        /**
         * Show the dialog. Invoked primarily from React Router transition
         * handler getUserConfirmation.
         *
         * @param allowTransitionCallback A function that accepts a flag whether or not to allow the route transition
         */
        _this.show = function (allowTransitionCallback) {
            _this.setState({ showModal: true }, function () { return allowTransitionCallback(false); });
        };
        _this.handleRedirect = function () {
            _this.setState({ redirect: true });
        };
        _this.handleClose = function () {
            _this.setState({ showModal: false });
        };
        /**
         * Handles the Router transition. Returns true if allowed
         * or the dialog trigger key to enable the dialog.
         */
        _this.handleTransition = function () { return Symbol.keyFor(_this.trigger); };
        // Define a unique global symbol to store
        // a dialog trigger reference accessible via
        // a string key. Use cuid for unique ID.
        _this.trigger = Symbol.for("__PreventTransitionPrompt_" + _.uniqueId());
        return _this;
    }
    /**
     * Attach global dialog trigger for this component
     * instance to our Symbol trigger
     */
    PreventTransitionPrompt.prototype.componentDidMount = function () {
        window[this.trigger] = this.show;
    };
    /**
     * Ensure we clean up and remove the reference
     * from the global object
     */
    PreventTransitionPrompt.prototype.componentWillUnmount = function () {
        delete window[this.trigger];
    };
    PreventTransitionPrompt.prototype.render = function () {
        var _a = this.props, when = _a.when, title = _a.title, message = _a.message;
        var showModal = this.state.showModal;
        if (this.state.redirect) {
            return React.createElement(Redirect, { to: this.props.redirectPath });
        }
        return (React.createElement(React.Fragment, null,
            React.createElement(Prompt, { when: when, message: this.handleTransition }),
            React.createElement(Modal, { showModal: showModal, onConfirm: this.handleRedirect, onCancel: this.handleClose },
                React.createElement("h2", null, title),
                React.createElement("div", null, message))));
    };
    return PreventTransitionPrompt;
}(React.Component));
export default PreventTransitionPrompt;
