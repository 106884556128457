var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _this = this;
import axios from 'axios';
var Territory = /** @class */ (function () {
    function Territory() {
    }
    Territory.territories = {
        PR: {
            title: 'Puerto Rico',
            message: 'Your vehicle was originally distributed and sold in the US Territory Puerto Rico by the Bella Group. For any questions about Customer Reimbursement, please contact the Bella Group at www.mazdapr.com or at 787-641-1777. All unrepaired vehicle campaigns can be repaired by any Mazda dealer in the US Territory or the USA, Canada or Mexico.'
        },
        GM: {
            title: 'Guam',
            message: 'Your vehicle was originally distributed and sold in the US Territory Guam by Triple J Motors. For any questions about Customer Reimbursement, please contact Triple J Motors at www.carsguam.com or at 671-648-2277. All unrepaired vehicle campaigns can be repaired by any Mazda dealer in the US Territory or the USA, Canada or Mexico.'
        },
        SP: {
            title: 'Saipan',
            message: 'Your vehicle was originally distributed and sold in the US Territory Saipan by Triple J Motors. For any questions about Customer Reimbursement, please contact Triple J Motors at www.carssaipan.com or at 670-234-7133. All unrepaired vehicle campaigns can be repaired by any Mazda dealer in the US Territory or the USA, Canada or Mexico'
        },
        default: {
            title: 'Non USA Vin',
            message: 'Your VIN is invalid or not US or US Territory VIN. Please contact the original distributor for your vehicle regarding any campaign reimbursement'
        }
    };
    Territory.usCode = 'US';
    Territory.lookupTerritory = function (vin) { return __awaiter(_this, void 0, void 0, function () {
        var response, territoryCode;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axios.get("/api/territory?vin=" + vin)];
                case 1:
                    response = _a.sent();
                    territoryCode = response.data;
                    if (territoryCode === Territory.usCode) {
                        return [2 /*return*/, undefined];
                    }
                    return [2 /*return*/, Territory.territories[territoryCode] || Territory.territories.default];
            }
        });
    }); };
    return Territory;
}());
export { Territory };
