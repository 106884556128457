var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { UNITED_STATES } from '../constants';
var CampaignOptions = /** @class */ (function () {
    function CampaignOptions(mfrRecallNumber) {
        var _this = this;
        this.campaignOptions = {
            country: UNITED_STATES,
            reimbursementAmount: null
        };
        this.specificOptions = {
            CSP05: {
                reimbursementAmount: '400'
            }
        };
        this.getCountry = function () { return _this.campaignOptions.country; };
        this.getReimbursementAmount = function () { return _this.campaignOptions.reimbursementAmount; };
        this.setCampaignOptions = function (mfrRecallNumber) {
            if (_this.specificOptions.hasOwnProperty(mfrRecallNumber)) {
                return __assign({}, _this.campaignOptions, _this.specificOptions[mfrRecallNumber]);
            }
            return _this.campaignOptions;
        };
        try {
            this.campaignOptions = this.setCampaignOptions(mfrRecallNumber);
        }
        catch (error) {
            console.error(CampaignOptions.name + " Error", error);
        }
    }
    return CampaignOptions;
}());
export { CampaignOptions };
