import React from 'react';
var styles = require('./status.scss');
export var CaseStatus;
(function (CaseStatus) {
    CaseStatus["PENDING"] = "inReview";
    CaseStatus["IN REVIEW"] = "inReview";
    CaseStatus["PROCESSING"] = "inReview";
    CaseStatus["AWAITING CUSTOMER"] = "awaitingCustomer";
    CaseStatus["RELEASED FOR PAYMENT"] = "releasedForPayment";
    CaseStatus["PAID"] = "paid";
    CaseStatus["FINAL PAYMENT"] = "paid";
    CaseStatus["DENIED"] = "denied";
})(CaseStatus || (CaseStatus = {}));
var Status = function (props) {
    return React.createElement("div", { className: [styles.status, styles[CaseStatus[props.status] || 'default']].join(' ') }, props.status);
};
export default Status;
