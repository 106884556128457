import * as React from 'react';
import { statusConversion } from "../types/campaign";
import Interweave from "interweave";
var globalStyles = require('../application/application.scss');
var styles = require('./legacy-recalls.scss');
var LegacyRecalls = function (props) {
    return (React.createElement("div", { className: styles.list }, props.recalls.map(function (recall) {
        return (React.createElement("div", { className: styles.recall, "data-test": "recall" },
            React.createElement("div", { className: styles.info },
                React.createElement("div", null, recall.recall_description),
                React.createElement("div", { className: globalStyles.campaignInstructions }, recall.safety_risk_description)),
            React.createElement("div", { className: globalStyles.campaignStatus },
                React.createElement("div", { className: globalStyles.campaignStatusLabel, "data-test": "status" },
                    React.createElement(Interweave, { content: String(statusConversion(recall.mfr_recall_status)) })))));
    })));
};
export default LegacyRecalls;
