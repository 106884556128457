var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { Field, connect } from 'formik';
import Interweave from 'interweave';
import * as Yup from 'yup';
import RadioButton from '../ui/radio-button';
import FieldError from '../ui/field-error';
import Button from '../ui/button';
var globalStyles = require('../application/application.scss');
var styles = require('./campaign-form.scss');
export var CampaignFormSchema = Yup.object().shape({
    campaignId: Yup.string().required()
});
var CampaignForm = function (props) {
    var _a = props.formik, errors = _a.errors, values = _a.values;
    var isValid = function (propName) {
        return !errors[propName] && values[propName] != '';
    };
    return (React.createElement("section", { className: styles.campaignForm },
        React.createElement("fieldset", null,
            React.createElement("div", null,
                React.createElement("p", { className: globalStyles.instructions },
                    "All fields are required except where noted.",
                    React.createElement("br", null),
                    "No special characters or punctuation allowed."),
                React.createElement("h4", null, "Select a Recall Campaign with an Eligible Customer Reimbursement Program"),
                React.createElement("ul", { className: styles.campaignList }, props.campaigns.map(function (campaign) {
                    return (React.createElement("li", { key: campaign.id },
                        React.createElement(Field, { name: "campaignId", render: function (props) {
                                return (React.createElement(RadioButton, __assign({}, props, { value: campaign.id, id: campaign.id, dataTest: "campaign" }),
                                    React.createElement("div", { className: styles.campaignLabel },
                                        React.createElement("div", { className: globalStyles.title }, "Campaign"),
                                        React.createElement("div", null, campaign.projectName),
                                        React.createElement("div", { className: globalStyles.campaignInstructions },
                                            React.createElement(Interweave, { content: campaign.instructions }))),
                                    React.createElement("div", { className: globalStyles.campaignStatus },
                                        React.createElement("div", { className: globalStyles.campaignStatusLabel, "data-test": "status" }, campaign.status))));
                            } })));
                })),
                React.createElement(FieldError, { name: "campaign" }))),
        React.createElement(Button, { variant: "primary", type: "submit", disabled: !isValid('campaignId'), onClick: props.updateProgress, dataTest: "campaign_submit" }, "Continue")));
};
export default connect(CampaignForm);
