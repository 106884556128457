import 'core-js';
import * as React from 'react';
import ReactDOM from 'react-dom';
import Application from './application/application';
import Incompatible from './application/incompatible';
import supportedBrowserDetected from './utils/browser-detect';
if (supportedBrowserDetected) {
    ReactDOM.render(React.createElement(Application, null), document.getElementsByTagName('main')[0]);
}
else {
    ReactDOM.render(React.createElement(Incompatible, null), document.getElementsByTagName('main')[0]);
}
