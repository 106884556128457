var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import { Switch, Route } from 'react-router';
import { withVin } from './vin-context';
import ClaimWizard from '../create-claim/claim-wizard';
import ClaimList from '../find-claim/claim-list';
import ClaimDetail from '../find-claim/claim-detail';
import SideBar from './side-bar';
import TitleBar from './title-bar';
import FooterBar from './footer-bar';
import NotFound from './not-found';
var styles = require('./route-container.scss');
var RouteContainer = /** @class */ (function (_super) {
    __extends(RouteContainer, _super);
    function RouteContainer() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    RouteContainer.prototype.render = function () {
        return (React.createElement("article", { className: styles.container },
            React.createElement(TitleBar, null),
            React.createElement("section", null,
                React.createElement(SideBar, { vin: this.props.vin }),
                React.createElement(Switch, null,
                    React.createElement(Route, { path: "/submit-claim", component: ClaimWizard }),
                    React.createElement(Route, { path: "/claims", exact: true, component: ClaimList }),
                    React.createElement(Route, { path: "/claims/summary", component: ClaimDetail }),
                    React.createElement(Route, { component: NotFound }))),
            React.createElement(FooterBar, null)));
    };
    return RouteContainer;
}(React.Component));
export default withVin(RouteContainer);
