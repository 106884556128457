var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import * as React from 'react';
import _ from 'lodash';
import numWords from 'num-words';
import { connect } from 'formik';
import { toMegabytes } from '../utils/to-megabytes';
import Modal from '../ui/modal';
import Button from '../ui/button';
import Icon from '../ui/icon';
import { MIN_FILE_SIZE_BYTE, MAX_FILE_SIZE_MB, FILE_TYPES, DISCLAIMER_FILENAME, RENAMED_DISCLAIMER_FILENAME, DISCLAIMER_MIMETYPE, FILE_ERROR_MESSAGE, UPLOAD_ERROR_ASSISTANCE_MESSAGE } from '../constants';
var globalStyles = require('../application/application.scss');
var styles = require('./file-upload.scss');
var FileUpload = /** @class */ (function (_super) {
    __extends(FileUpload, _super);
    function FileUpload() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            showModal: false,
            fileToRemove: null,
            fileSizeError: false,
            fileLimitError: false,
            invalidFiles: []
        };
        _this.fileInput = React.createRef();
        /**
         * @method handleFileAdd
         * @description
         * event.currentTarget.value = '' to clear the file input state
         */
        _this.handleFileAdd = function (event) {
            var _a = _this.props.formik, values = _a.values, setFieldValue = _a.setFieldValue;
            var files = _.toArray(event.currentTarget.files);
            event.currentTarget.value = '';
            var filesToAttach = [];
            var invalidFiles = [];
            _.forEach(files, function (file) {
                if (file.size >= MIN_FILE_SIZE_BYTE && toMegabytes(file.size) <= MAX_FILE_SIZE_MB) {
                    var verifiedFile = _this.renameIfDisclaimer(file);
                    filesToAttach.push(verifiedFile);
                }
                else {
                    invalidFiles.push(file.name);
                }
            });
            var fileCount = filesToAttach.length + values.files.length;
            if (fileCount >= _this.props.minFiles && fileCount <= _this.props.maxFiles) {
                setFieldValue('files', __spread(filesToAttach, values.files));
            }
            else if (fileCount > _this.props.maxFiles) {
                _this.setState({ fileLimitError: true });
            }
            if (invalidFiles.length) {
                _this.setState({ fileSizeError: true, invalidFiles: invalidFiles });
            }
        };
        return _this;
    }
    FileUpload.prototype.handleRemoveFile = function (event, fileToRemove) {
        event.preventDefault();
        this.setState({ showModal: true, fileToRemove: fileToRemove });
    };
    FileUpload.prototype.deleteFile = function () {
        var _this = this;
        this.setState({ showModal: false });
        var _a = this.props.formik, values = _a.values, setFieldValue = _a.setFieldValue;
        var newValue = values.files.filter(function (file) { return file !== _this.state.fileToRemove; });
        setFieldValue('files', newValue);
    };
    FileUpload.prototype.renameIfDisclaimer = function (file) {
        if (file.type === DISCLAIMER_MIMETYPE && file.name === DISCLAIMER_FILENAME) {
            var renamedFile = new File([file], RENAMED_DISCLAIMER_FILENAME, {
                type: DISCLAIMER_MIMETYPE
            });
            return renamedFile;
        }
        else {
            return file;
        }
    };
    FileUpload.prototype.displayInvalidFiles = function () {
        return this.state.invalidFiles.map(function (filename, index) {
            return (React.createElement("div", { key: index, className: styles.file },
                React.createElement("div", null,
                    React.createElement(Icon, { icon: 'file_icon', class: 'iconFile', fill: '', width: '18', height: '18' })),
                React.createElement("div", { className: styles.fileName }, filename)));
        });
    };
    FileUpload.prototype.render = function () {
        var _this = this;
        var values = this.props.formik.values;
        return (React.createElement(React.Fragment, null,
            React.createElement(Modal, { showModal: this.state.fileSizeError, onConfirm: function () { return _this.setState({ fileSizeError: false }); } },
                React.createElement("h2", null, FILE_ERROR_MESSAGE),
                this.displayInvalidFiles(),
                React.createElement("p", null, UPLOAD_ERROR_ASSISTANCE_MESSAGE)),
            React.createElement(Modal, { showModal: this.state.fileLimitError, onConfirm: function () { return _this.setState({ fileLimitError: false }); } },
                React.createElement("h2", null,
                    "You may not add more than ",
                    numWords(this.props.maxFiles),
                    " attachments")),
            React.createElement(Modal, { showModal: this.state.showModal, onConfirm: function () { return _this.deleteFile(); }, onCancel: function () { return _this.setState({ showModal: false, fileToRemove: null }); } },
                React.createElement("h2", null, "Do you want to remove this file?"),
                React.createElement("div", { className: styles.file },
                    React.createElement("div", null,
                        React.createElement(Icon, { icon: 'file_icon', class: 'iconFile', fill: '', width: '18', height: '18' })),
                    React.createElement("div", { className: styles.fileName }, this.state.fileToRemove && this.state.fileToRemove.name))),
            React.createElement("div", { className: styles.uploadContainer },
                React.createElement("div", null,
                    React.createElement("label", { htmlFor: "files", className: styles.fileUpload }, "Select Files"),
                    React.createElement("input", { id: "files", name: "files", type: "file", multiple: this.props.maxFiles < 1, ref: this.fileInput, onChange: this.handleFileAdd, className: styles.smallInput, accept: FILE_TYPES.join(','), "data-test": 'file_upload' })),
                values.files.length > 0 && (React.createElement("div", { className: styles.uploadList },
                    React.createElement("span", { className: globalStyles.title }, "attachments"),
                    values.files.map(function (file, index) {
                        return (React.createElement("div", { key: index, className: styles.fileSet },
                            React.createElement("div", null,
                                React.createElement(Icon, { icon: 'file_icon', class: 'iconFile', fill: '', width: '18', height: '18' })),
                            React.createElement("div", { className: styles.fileName }, file.name),
                            React.createElement("div", null,
                                React.createElement(Button, { variant: 'invisible', onClick: function (event) { return _this.handleRemoveFile(event, file); } },
                                    React.createElement(Icon, { icon: 'remove_icon', class: 'iconTrash', fill: '#367397', width: '20', height: '20', alt: "Delete" })))));
                    }))))));
    };
    return FileUpload;
}(React.Component));
export default connect(FileUpload);
