var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import VinLookUp from '../create-claim/vin-look-up-form';
import CheckStatus from '../find-claim/check-status-form';
import { CUSTOMER_SERVICE_NUMBER, MAZDA_DEALER_LOOKUP_URL } from "../constants";
import FooterBar from './footer-bar';
var styles = require('./homepage.scss');
var logo = 'https://jnr-email-assets.s3-us-west-2.amazonaws.com/mazda/logo.png';
var Homepage = /** @class */ (function (_super) {
    __extends(Homepage, _super);
    function Homepage() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            isLookup: true
        };
        return _this;
    }
    Homepage.prototype.render = function () {
        var _this = this;
        return (React.createElement("article", { className: styles.homepageContainer },
            React.createElement("section", { className: styles.oemPanel },
                React.createElement("img", { src: logo }),
                React.createElement("p", null, "Mazda has a reimbursement program in place for repairs performed on your vehicle that were related to a recall or service campaign where you paid out of pocket for the expense prior to the launch of the campaign. To file a claim, please enter your VIN on the right and follow the screen prompts to choose the recall or special service program available for your vehicle, and fill out the form and attach the required documentation."),
                React.createElement("p", null,
                    React.createElement("span", { className: styles.title }, "Reimbursement Claim Form"),
                    React.createElement("br", null),
                    "In order to process your claim completely, please have the following information.",
                    React.createElement("ul", null,
                        React.createElement("li", null, "Your current name and address"),
                        React.createElement("li", null, "A current, valid email address"),
                        React.createElement("li", null,
                            "Paid repair order or invoice receipt showing:",
                            React.createElement("ul", null,
                                React.createElement("li", null, "Vehicle model and year, and vehicle identification number (VIN)"),
                                React.createElement("li", null, "Your name and address at the time of repair"),
                                React.createElement("li", null, "Description of the recall defect or noncompliance concern reported"),
                                React.createElement("li", null, "Total amount paid for repair of the concern"),
                                React.createElement("li", null, "Proof of payment for repairs completed (cash or credit card receipt, or cancelled check copy)")))),
                    "Note: Additional information may be required if the repair order or invoice receipt are unclear that the repair addresses the recall defect or noncompliance. For a replacement vehicle part, the receipt must identify the item and the total amount paid."),
                React.createElement("p", null,
                    "If you received a safety recall, please keep in mind, reimbursement for these repairs does not satisfy the campaign completion. Prior to being reimbursed please visit your nearest Mazda dealership for recall repairs and then after they are completed, submit your Customer Reimbursement Claim. You can find a Mazda dealer ",
                    React.createElement("a", { href: MAZDA_DEALER_LOOKUP_URL, target: "_blank" }, "here"),
                    " to make an appointment for repairs."),
                React.createElement("p", null, "If you no longer own the vehicle, please fill out the form and explain in the notes section why you cannot complete the recall."),
                React.createElement("p", null,
                    "If you need any assistance filling out this form, please contact the Mazda Customer Experience Center at ",
                    CUSTOMER_SERVICE_NUMBER,
                    ", Option #4.")),
            React.createElement("section", { className: styles.formPanel }, this.state.isLookup ? (React.createElement(React.Fragment, null,
                React.createElement(VinLookUp, null),
                React.createElement("a", { onClick: function () { return _this.setState({ isLookup: false }); } }, "Already submitted a claim? Click here for the status."))) : (React.createElement(React.Fragment, null,
                React.createElement(CheckStatus, null),
                React.createElement("a", { onClick: function () { return _this.setState({ isLookup: true }); } }, "Need to submit a new claim? Click here.")))),
            React.createElement(FooterBar, null)));
    };
    return Homepage;
}(React.Component));
export default Homepage;
