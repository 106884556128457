var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { Route, Switch } from 'react-router';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from './vin-context';
import Homepage from './homepage';
import RouteContainer from './route-container';
import Error from './error';
var basePath = window.location.pathname.split('/')[1] === 'site' ? '/site' : '/';
var Application = /** @class */ (function (_super) {
    __extends(Application, _super);
    function Application() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            vin: window.localStorage.getItem('vin') || '',
            submittable: JSON.parse(window.localStorage.getItem('submittable')) || [],
            legacy: JSON.parse(window.localStorage.getItem('legacy')) || [],
        };
        _this.getUserConfirmation = function (dialogKey, callback) {
            // use "message" as Symbol-based key
            var dialogTrigger = window[Symbol.for(dialogKey)];
            if (dialogTrigger) {
                return dialogTrigger(callback);
            }
            // Fallback to allowing navigation
            callback(true);
        };
        return _this;
    }
    Application.prototype.render = function () {
        var _this = this;
        return (React.createElement("article", null,
            React.createElement(Router, { basename: "" + basePath, getUserConfirmation: this.getUserConfirmation },
                React.createElement(Provider, { value: {
                        vin: this.state.vin,
                        submittable: this.state.submittable,
                        legacy: this.state.legacy,
                        update: function (vin) { return _this.updateVin(vin); },
                        updateSubmittable: function (submittable) { return _this.updateSubmittable(submittable); },
                        updateLegacy: function (legacy) { return _this.updateLegacy(legacy); }
                    } },
                    React.createElement(Switch, null,
                        React.createElement(Route, { path: "/error", component: Error }),
                        React.createElement(Route, { path: "/", exact: true, component: Homepage }),
                        React.createElement(Route, { path: "/", component: RouteContainer }))))));
    };
    Application.prototype.updateVin = function (vin) {
        window.localStorage.setItem('vin', vin);
        this.setState({ vin: vin });
    };
    Application.prototype.updateSubmittable = function (submittable) {
        window.localStorage.setItem('submittable', JSON.stringify(submittable));
        this.setState(function (prevState) { return (__assign({}, prevState, { submittable: submittable })); });
    };
    Application.prototype.updateLegacy = function (legacy) {
        window.localStorage.setItem('legacy', JSON.stringify(legacy));
        this.setState(function (prevState) { return (__assign({}, prevState, { legacy: legacy })); });
    };
    return Application;
}(React.Component));
export default Application;
