import * as React from 'react';
import Icon from './icon';
var styles = require('./general-message.scss');
function renderIcon(type) {
    if (type === 'success') {
        return React.createElement(Icon, { icon: 'success_icon', class: 'iconSuccess', width: '25', height: '25' });
    }
    if (type === 'warning') {
        return React.createElement(Icon, { icon: 'warning_icon', class: 'iconWarning', width: '25', height: '25' });
    }
    if (type === 'error') {
        return React.createElement(Icon, { icon: 'error_icon', class: 'iconError', width: '25', height: '25' });
    }
}
var GeneralMessage = function (props) {
    return (React.createElement("div", { className: [styles.generalMessage, styles[props.type]].join(' '), "data-test": props.dataTest },
        React.createElement("div", { className: styles.icon }, renderIcon(props.type)),
        React.createElement("div", null,
            React.createElement("h2", null, props.title),
            typeof props.description == "string" && (React.createElement("div", null, props.description)),
            typeof props.description != "string" && (React.createElement(props.description, null)),
            props.children)));
};
export default GeneralMessage;
