var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from 'react';
import { Redirect } from 'react-router';
import { Formik, Form } from 'formik';
import axios from 'axios';
import * as Yup from 'yup';
import { Consumer } from '../application/vin-context';
import FormField from '../ui/form-field';
import Button from '../ui/button';
import GeneralMessage from '../ui/general-message';
import { INVALID_VIN_MESSAGE, NO_RECALLS_MESSAGE, NON_US_VIN_MESSAGE } from '../constants';
import ReCAPTCHA from 'react-google-recaptcha';
var styles = require('../application/homepage.scss');
var FormSchema = Yup.object().shape({
    vin: Yup.string()
        .matches(/[A-Z0-9]{17}/i, 'Please enter exactly 17 letters/digits')
        .required('Please enter your VIN')
});
var VinLookUp = /** @class */ (function (_super) {
    __extends(VinLookUp, _super);
    function VinLookUp(props) {
        var _this = _super.call(this, props) || this;
        _this.reCaptchaRef = React.createRef();
        _this.state = {
            isRedirectedToClaimWizard: false,
            isRedirectedToErrorPage: false,
            noRecalls: false,
            unknownVin: false,
            nonUsVin: false,
            isReCaptchaSuccessful: false,
            reCaptchaResponse: ''
        };
        _this.initialState = _this.state;
        return _this;
    }
    VinLookUp.prototype.render = function () {
        var _this = this;
        if (this.state.isRedirectedToClaimWizard) {
            return React.createElement(Redirect, { to: "/submit-claim", push: true });
        }
        if (this.state.isRedirectedToErrorPage) {
            return React.createElement(Redirect, { to: "/error" });
        }
        return (React.createElement(Consumer, null, function (context) { return (React.createElement(Formik, { initialValues: { vin: '' }, validationSchema: FormSchema, onSubmit: function (formikFormValues, formikActions) { return __awaiter(_this, void 0, void 0, function () {
                var vin, reCaptchaResponse, response, _a, submittable, legacy, err_1;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            if (!this.state.isReCaptchaSuccessful) {
                                return [2 /*return*/, this.setState({ isRedirectedToErrorPage: true })];
                            }
                            _b.label = 1;
                        case 1:
                            _b.trys.push([1, 3, 4, 5]);
                            this.resetFormErrors(formikActions);
                            vin = formikFormValues.vin.toUpperCase();
                            reCaptchaResponse = this.state.reCaptchaResponse;
                            return [4 /*yield*/, axios.post('/api/vin', { vin: vin, reCaptchaResponse: reCaptchaResponse })];
                        case 2:
                            response = _b.sent();
                            _a = response.data, submittable = _a.submittable, legacy = _a.legacy;
                            if (submittable.length === 0 && legacy.length === 0) {
                                return [2 /*return*/, this.setNoRecallVinError(formikActions)];
                            }
                            context.update(vin);
                            context.updateSubmittable(submittable);
                            context.updateLegacy(legacy);
                            return [2 /*return*/, this.setState({ isRedirectedToClaimWizard: true })];
                        case 3:
                            err_1 = _b.sent();
                            if (err_1.response.status === 404) {
                                return [2 /*return*/, this.setUnknownVinError(formikActions)];
                            }
                            if (err_1.response.status === 422) {
                                return [2 /*return*/, this.setNonUsVinError(formikActions)];
                            }
                            return [2 /*return*/, this.setState({ isRedirectedToErrorPage: true })];
                        case 4:
                            formikActions.setSubmitting(false);
                            return [7 /*endfinally*/];
                        case 5: return [2 /*return*/];
                    }
                });
            }); } }, function (_a) {
            var isSubmitting = _a.isSubmitting, isValid = _a.isValid;
            return (React.createElement("div", { className: styles.lookupContainer },
                _this.state.unknownVin && (React.createElement(GeneralMessage, { type: "error", title: "Unknown VIN", description: INVALID_VIN_MESSAGE, dataTest: "unknown_vin_notice" })),
                _this.state.nonUsVin && (React.createElement(GeneralMessage, { type: "error", title: "Non US Vin", description: NON_US_VIN_MESSAGE, dataTest: "non_us_vin_notice" })),
                _this.state.noRecalls && (React.createElement(GeneralMessage, { type: "success", title: "No Recalls", description: NO_RECALLS_MESSAGE, dataTest: "no_recalls_notice" })),
                React.createElement("h3", null, "Enter your 17 digit VIN to find eligible recalls"),
                React.createElement(Form, { className: styles.lookupForm },
                    React.createElement(FormField, { className: styles.vinFieldContainer, name: "vin", type: "text", maxLength: "17", "data-test": "vin" }),
                    React.createElement(ReCAPTCHA, { sitekey: "6Lc6z1IcAAAAABcaL4yz7OWeFY4YsiyW3FCRmJsL", ref: _this.reCaptchaRef, onChange: function (reCaptchaResponse) {
                            // onChange is called with a response from google when the user successfully completes captcha
                            _this.setState({ isReCaptchaSuccessful: true });
                            _this.setState({ reCaptchaResponse: reCaptchaResponse });
                        }, onExpired: function () {
                            _this.setState({ isReCaptchaSuccessful: false });
                            _this.setState({ reCaptchaResponse: '' });
                        }, onErrored: function () {
                            _this.setState({ isReCaptchaSuccessful: false });
                            _this.setState({ reCaptchaResponse: '' });
                        } }),
                    React.createElement(Button, { variant: "primary", type: "submit", disabled: !_this.state.isReCaptchaSuccessful || isSubmitting || !isValid, "data-test": "submit" }, "Look Up"))));
        })); }));
    };
    VinLookUp.prototype.resetFormErrors = function (formikActions) {
        this.reCaptchaRef.current.reset();
        this.setState({ noRecalls: false, unknownVin: false, nonUsVin: false });
        formikActions.setStatus();
    };
    VinLookUp.prototype.setNoRecallVinError = function (formikActions) {
        formikActions.setFieldError('vin', 'No recalls');
        this.setState({ noRecalls: true });
    };
    VinLookUp.prototype.setUnknownVinError = function (formikActions) {
        formikActions.setFieldError('vin', 'Unknown VIN');
        this.setState({ unknownVin: true });
    };
    VinLookUp.prototype.setNonUsVinError = function (formikActions) {
        formikActions.setFieldError('vin', 'Non Us Vin Supplied');
        this.setState({ nonUsVin: true });
    };
    return VinLookUp;
}(React.Component));
export default VinLookUp;
